.Footer {
    position: absolute;
    bottom: 10vh;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.Copyright {
    text-align: center;
    text-shadow: 0 0 1px #fff;
}
