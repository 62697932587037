.Avatar {
    margin: auto;
    background-position: center;
    background-size: cover;
    background-image: url("../../../public/images/me.jpg");
    border: 15px #00000030 solid;
    width: 40vh;
    height: 40vh;
    border-radius: 40vh;
    animation: avatarAnimation 1s ease-in-out;
    position: absolute;
    left: calc(50% - 20vh);
    top: calc(50% - 20vh);
    z-index: 1;
    filter: none;
    transition: all 0.5s;
    opacity: .9;
}

.Avatar:hover {
    filter: grayscale(100%) brightness(1.1);
    transform: scale(1.1);
    transition: all 0.5s;
    cursor: pointer;
    border: 15px transparent solid;
}

@keyframes avatarAnimation {
    from {
        opacity: 0;
        top: -50vh;
        transform: rotate(0deg);
    }

    40% {
        opacity: .4;
        top: calc(100% - 40vh);
        transform: rotate(360deg);
    }

    to {
        opacity: .9;
        top: calc(50% - 20vh);
        transform: rotate(360deg);
    }
}
